<template>
	<div>
		<!-- start page title -->
        <div class="row">
            <div class="col-12">
                <div class="page-title-box">
                    <h4 class="page-title">Главная</h4>
                </div>
            </div>
        </div>
        <!-- end page title -->
	</div>
</template>

<script>
export default {
	name: 'Landing',
    mounted() {
        this.$router.push({ name: 'ProjectList' })
    }
}
</script>